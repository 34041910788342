import * as React from "react"
import { SVGProps } from "react"

const Question = (props: SVGProps<SVGSVGElement>) => (
    <svg width="19"
         height="19"
         viewBox="0 0 19 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">

        <circle cx="9.5" cy="9.5" r="7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        <path
            d="M7.55469 7.30543C7.74469 5.17465 11.5208 5.23832 11.5208 7.58472C11.5208 9.12091 9.50843 9.10306 9.50843 11.0106"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        <circle cx="9.49844" cy="13.5565" r="0.85" fill="currentColor"/>
    </svg>

)

export default Question
