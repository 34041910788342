import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import FaqIcon from "../../../assets/_V2/svg/FaqIcon"
import Question from "./Question"

interface QuestionData {
	question: string
	answer: JSX.Element
}

interface FaqData {
	blockName: string
	title: string
	questions: QuestionData[]
}

interface Props {
	className?: string
	data: FaqData
	withShadow: boolean
}

export default function Faq(props: Props) {
	return (
		<section
			className={classNames(styles.faq, props.className, {
				[styles.faqWithShadow]: props.withShadow,
			})}
		>
			<div className={styles.faq__topRow}>
				<FaqIcon className={styles.faq__icon} />

				<div>
					<span className={styles.faq__blockName}>{props.data.blockName}</span>
					<h2 className={styles.faq__title}>{props.data.title}</h2>
				</div>
			</div>

			<div className={styles.faq__content}>
				{props.data.questions.map(question => (
					<Question question={question} key={question.question} />
				))}
			</div>
		</section>
	)
}
