import React, { Dispatch, SetStateAction } from "react"

import { requestForEnterpriseTariff } from "../../../apiRequests/callToAction"
import { RequestCallModal } from "../RequestCallModal/RequestCallModal"

import data from "./data"

interface Props {
	className?: string
	isOpen: boolean
	onClose: Dispatch<SetStateAction<boolean>>
}

export default function EnterpriseCtaForm(props: Props) {
	return (
		<>
			<RequestCallModal
				isOpen={props.isOpen}
				onClose={props.onClose}
				modalData={data.modal}
				inputTitle={data.inputTitle}
				showBackdropShape={true}
				request={requestForEnterpriseTariff}
			/>
		</>
	)
}
