import * as React from "react"

const FryEnterprise = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="136"
		height="122"
		viewBox="0 0 136 122"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M113.711 36.5556C118.616 35.7397 120.675 38.1618 121.092 39.4748H119.588L118.109 40.5073L117.726 42.8402L115.521 42.3175L113.711 36.5556Z"
			fill="#FEEA00"
		/>
		<path
			d="M125.944 38.5542L126.206 40.3098L124.498 41.089L123.127 42.0748L122.967 41.089L122.526 40.3098L121.324 39.5118L121.23 39.2771L121.324 38.4697L121.888 37.4745L122.676 36.9581L123.878 36.7986L124.892 37.1083L125.624 37.8219L125.944 38.5542Z"
			fill="#FEEA00"
		/>
		<path
			d="M119.169 43.5009L117.641 42.8157L118.07 40.5265L119.583 39.427L121.278 39.5174L122.505 40.2931L122.987 41.0987L123.138 42.055L122.543 42.4768L121.586 43.403L120.698 44.397L119.169 43.5009Z"
			fill="#FEEA00"
		/>
		<path
			d="M126.166 40.017L125.965 38.6894C125.779 37.4619 124.58 36.6256 123.285 36.8213C121.991 37.0171 121.092 38.1708 121.278 39.3983"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M118.473 28.9688C120.173 28.7275 121.796 29.3601 122.887 30.5251C123.072 30.4665 123.264 30.4224 123.462 30.3943C125.392 30.1204 127.179 31.4631 127.453 33.3932L127.97 36.8109"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M118.309 28.9937C116.613 29.266 115.25 30.3502 114.552 31.7857C114.358 31.7844 114.162 31.7991 113.964 31.8308C112.039 32.14 110.729 33.9509 111.039 35.8756L111.555 39.2934"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M131.181 68.8702L130.938 65.7972M118.512 48.026C121.051 42.3013 125.496 40.0349 128.881 39.5877L129.599 48.6636"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M131.178 68.8703C131.178 68.8703 128.383 71.0287 124.812 71.5688C121.24 72.109 117.932 70.8736 117.932 70.8736L113.551 51.4468M120.703 44.3865C117.414 42.1636 114.003 41.7887 111.47 42.2204L112.607 47.2606"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M123.143 41.7317C122.911 40.1978 121.479 39.1424 119.945 39.3744C118.411 39.6064 117.356 41.0379 117.588 42.5718"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M115.438 42.0193L113.715 36.564C116.693 35.6773 119.755 36.8924 121.084 39.435"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.8468 62.9549C11.435 62.5583 8.27255 60.5338 6.56358 57.259C4.85462 53.9842 5.00253 50.2321 6.62847 47.2066C10.0403 47.6032 13.2027 49.6277 14.9117 52.9025C16.6206 56.1773 16.4727 59.9293 14.8468 62.9549Z"
			fill="#FEEA00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M100.441 62.6459L108.451 58.0131C108.604 57.7697 108.749 57.5184 108.884 57.2592C108.916 57.1983 108.947 57.1372 108.977 57.076C108.223 55.4985 107.586 52.3892 109.721 49.473L109.725 49.4793C109.511 48.6914 109.207 47.9284 108.819 47.2068C105.407 47.6034 102.245 49.6279 100.536 52.9027C98.8846 56.0668 98.9669 59.6765 100.441 62.6459Z"
			fill="#FEEA00"
		/>
		<path
			d="M16.1117 74.8032C12.5083 75.5202 8.62414 74.4981 5.80878 71.7259C2.99343 68.9537 1.91151 65.0857 2.57279 61.4717C6.17613 60.7547 10.0603 61.7768 12.8757 64.549C15.691 67.3212 16.773 71.1892 16.1117 74.8032Z"
			fill="#FEEA00"
		/>
		<path
			d="M20.3742 86.2636C16.9591 88.1742 12.688 88.415 8.92469 86.5315C5.16136 84.648 2.79419 81.0847 2.27658 77.2059C5.69168 75.2954 9.9628 75.0545 13.7261 76.9381C17.4895 78.8216 19.8566 82.3849 20.3742 86.2636Z"
			fill="#FEEA00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M95.0813 86.2401C98.4353 85.0996 105.118 79.3237 108.906 75.7644C110.405 75.9773 111.854 76.4666 113.176 77.2059C112.658 81.0847 110.291 84.648 106.528 86.5315C102.764 88.415 98.4932 88.1741 95.0781 86.2636C95.0792 86.2558 95.0802 86.2479 95.0813 86.2401Z"
			fill="#FEEA00"
		/>
		<path
			d="M28.2049 96.1125C25.3758 99.152 21.1396 100.783 16.7261 100.103C12.3125 99.4232 8.76358 96.5928 6.98048 92.8427C9.80954 89.8032 14.0458 88.1722 18.4593 88.8521C22.8729 89.5321 26.4218 92.3625 28.2049 96.1125Z"
			fill="#FEEA00"
		/>
		<path
			d="M87.2443 96.1125C90.0734 99.152 94.3096 100.783 98.7232 100.103C103.137 99.4232 106.686 96.5928 108.469 92.8427C105.64 89.8032 101.403 88.1722 96.9899 88.8521C92.5764 89.5321 89.0274 92.3625 87.2443 96.1125Z"
			fill="#FEEA00"
		/>
		<path
			d="M39.3892 103.268C37.5324 107.248 33.801 110.269 29.1386 111.022C24.4762 111.775 19.9835 110.083 16.968 106.89C18.8248 102.911 22.5563 99.8898 27.2186 99.1367C31.881 98.3835 36.3738 100.076 39.3892 103.268Z"
			fill="#FEEA00"
		/>
		<path
			d="M76.06 103.268C77.9168 107.248 81.6482 110.269 86.3106 111.022C90.973 111.775 95.4657 110.083 98.4812 106.89C96.6244 102.911 92.893 99.8898 88.2306 99.1367C83.5682 98.3835 79.0754 100.076 76.06 103.268Z"
			fill="#FEEA00"
		/>
		<path
			d="M52.8351 106.961C52.257 111.557 49.4871 115.796 45.0497 118.058C40.6122 120.321 35.5548 120.074 31.4957 117.844C32.0737 113.248 34.8437 109.01 39.2811 106.747C43.7185 104.484 48.7759 104.731 52.8351 106.961Z"
			fill="#FEEA00"
		/>
		<path
			d="M62.6142 106.961C63.1922 111.557 65.9621 115.796 70.3996 118.058C74.837 120.321 79.8944 120.074 83.9535 117.844C83.3755 113.248 80.6056 109.01 76.1681 106.747C71.7307 104.484 66.6733 104.731 62.6142 106.961Z"
			fill="#FEEA00"
		/>
		<path
			d="M16.3866 51.1588C13.4794 49.832 11.2585 47.1354 10.682 43.7474C10.1054 40.3595 11.3092 37.08 13.6139 34.8663C16.5211 36.1931 18.7419 38.8897 19.3185 42.2777C19.8951 45.6656 18.6912 48.9451 16.3866 51.1588Z"
			fill="#FEEA00"
		/>
		<path
			d="M99.0626 51.1588C101.97 49.832 104.191 47.1354 104.767 43.7474C105.344 40.3595 104.14 37.08 101.835 34.8663C98.9282 36.1931 96.7073 38.8897 96.1307 42.2777C95.5542 45.6656 96.758 48.9451 99.0626 51.1588Z"
			fill="#FEEA00"
		/>
		<path
			d="M14.8468 63.7801C11.435 63.3835 8.27255 61.3589 6.56358 58.0842C4.85462 54.8094 5.00253 51.0573 6.62847 48.0318C10.0403 48.4284 13.2027 50.4529 14.9117 53.7277C16.6206 57.0025 16.4727 60.7545 14.8468 63.7801Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M108.821 48.0318C105.409 48.4284 102.247 50.4529 100.538 53.7277C99.0044 56.6656 98.9657 59.9876 100.148 62.8246"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.1117 75.6281C12.5083 76.3451 8.62414 75.3231 5.80878 72.5508C2.99343 69.7786 1.91151 65.9107 2.57279 62.2967C6.17613 61.5797 10.0603 62.6018 12.8757 65.374C15.691 68.1462 16.773 72.0141 16.1117 75.6281Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M20.3742 87.0884C16.9591 88.9989 12.688 89.2397 8.92469 87.3562C5.16136 85.4727 2.79419 81.9094 2.27658 78.0306C5.69168 76.1201 9.9628 75.8793 13.7261 77.7628C17.4895 79.6463 19.8566 83.2096 20.3742 87.0884Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M95.0753 87.0886C98.4904 88.9991 102.761 89.24 106.525 87.3565C110.288 85.473 112.655 81.9097 113.173 78.0309C111.603 77.1528 109.853 76.6274 108.054 76.4989"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M95.0777 87.0884C98.4928 88.9989 102.764 89.2398 106.527 87.3563C110.291 85.4728 112.658 81.9095 113.175 78.0307"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M28.2049 96.9372C25.3758 99.9767 21.1396 101.608 16.7261 100.928C12.3125 100.248 8.76358 97.4175 6.98048 93.6675C9.80954 90.628 14.0458 88.9969 18.4593 89.6769C22.8729 90.3568 26.4218 93.1872 28.2049 96.9372Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M87.2443 96.9372C90.0734 99.9767 94.3096 101.608 98.7232 100.928C103.137 100.248 106.686 97.4175 108.469 93.6675C105.64 90.628 101.403 88.9969 96.9899 89.6769C92.5764 90.3568 89.0274 93.1872 87.2443 96.9372Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M39.3892 104.093C37.5324 108.073 33.801 111.094 29.1386 111.847C24.4762 112.6 19.9835 110.908 16.9681 107.715C18.8248 103.736 22.5563 100.715 27.2186 99.9616C31.881 99.2084 36.3738 100.901 39.3892 104.093Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M76.06 104.093C77.9168 108.073 81.6482 111.094 86.3106 111.847C90.973 112.6 95.4657 110.908 98.4812 107.715C96.6244 103.736 92.893 100.715 88.2306 99.9616C83.5682 99.2084 79.0754 100.901 76.06 104.093Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M52.8351 107.787C52.257 112.382 49.4871 116.621 45.0497 118.884C40.6122 121.147 35.5548 120.9 31.4957 118.669C32.0737 114.073 34.8437 109.835 39.2811 107.572C43.7185 105.309 48.7759 105.556 52.8351 107.787Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M62.6142 107.787C63.1922 112.382 65.9621 116.621 70.3996 118.884C74.837 121.147 79.8944 120.9 83.9535 118.669C83.3755 114.073 80.6056 109.835 76.1681 107.572C71.7307 105.309 66.6733 105.556 62.6142 107.787Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M16.3866 51.983C13.4794 50.6563 11.2585 47.9596 10.682 44.5717C10.1054 41.1837 11.3092 37.9042 13.6139 35.6905C16.5211 37.0173 18.7419 39.7139 19.3185 43.1019C19.8951 46.4898 18.6912 49.7693 16.3866 51.983Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M99.0626 51.983C101.97 50.6563 104.191 47.9596 104.767 44.5717C105.344 41.1837 104.14 37.9042 101.835 35.6905C98.9282 37.0173 96.7073 39.7139 96.1307 43.1019C95.5542 46.4898 96.758 49.7693 99.0626 51.983Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M109.56 49.7497C109.364 49.1578 109.117 48.5827 108.821 48.0318C105.409 48.4284 102.247 50.4529 100.538 53.7277C99.0018 56.6706 98.9656 59.9988 100.154 62.839"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M32.0938 88.9233C36.079 87.5346 43.3345 78.4449 40.1163 62.1516"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M53.7307 50.0049C46.7016 50.162 40.1955 51.3644 35.9922 53.2853C38.2255 56.2779 39.0935 58.3188 40.1079 62.1499L44.8961 61.8239C45.1996 63.1043 46.7459 66.8564 50.8609 65.9909C55.3825 65.0398 55.417 60.5345 50.1628 50.6613"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M52.7852 55.8177L55.0457 43.8577"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M71.6641 61.9925L72.9857 56.3687C73.9499 52.1305 79.7754 52.0704 79.5151 48.2068"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M113.562 51.4569L119.122 53.82C120.492 54.4284 122.088 53.7188 122.553 52.2939C122.96 51.0494 122.35 49.7001 121.147 49.1836L118.476 48.0371C116.443 47.1645 114.136 46.4864 112.163 47.4874C111.498 47.8252 110.883 48.2683 110.345 48.806C109.006 50.1451 108.254 51.9614 108.254 53.8552C108.254 55.749 109.006 57.5653 110.345 58.9044C110.725 59.2843 111.144 59.617 111.591 59.8987"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M60.7319 38.2716C60.2165 35.7567 57.76 34.1357 55.2451 34.6511C52.7301 35.1664 51.1092 37.623 51.6245 40.1379C52.1399 42.6528 54.5964 44.2738 57.1113 43.7584"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M36.7218 54.4019C31.7827 55.4306 24.8766 57.3236 19.9062 61.8367C21.0045 66.1944 26.3232 76.029 39.0358 80.4513"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.5209 63.71C18.9983 65.7198 17.3628 67.893 16.0469 70.3681"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.7344 66.0194C21.7344 66.0194 30.8694 60.6426 38.821 59.1792"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M92.332 67.3842L108.922 57.7332"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M116.74 69.0083C99.5606 83.0379 93.9134 93.0633 84.459 81.8958L83.832 81.1369"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M75.5547 41.9044C76.3895 41.9889 77.7232 41.8226 78.9232 41.6177C80.4737 41.3528 82.5213 39.9025 81.9676 38.4303C81.6232 37.5144 81.0711 36.5448 80.1875 35.8544C79.1089 35.0117 78.1379 33.641 77.3611 32.4219C76.9398 31.7607 76.6405 30.9966 76.8216 30.2338C76.9074 29.8723 77.0547 29.4744 77.2957 29.0894"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M81.5945 40.8921C82.1479 41.9298 83.6309 44.0713 84.5717 45.1228C85.6672 46.3473 79.8926 50.7013 67.5938 46.409"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M67.6702 44.8484C67.6789 46.4588 67.1702 47.493 66.6797 48.062"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="122.941"
			y="63.4956"
			width="10.0944"
			height="5.36027"
			rx="2.68014"
			transform="rotate(-24.4158 122.941 63.4956)"
			stroke="#2A2D33"
			strokeWidth="2"
		/>
		<rect
			x="122.262"
			y="57.9053"
			width="10.904"
			height="5.36027"
			rx="2.68014"
			transform="rotate(-24.4158 122.262 57.9053)"
			stroke="#2A2D33"
			strokeWidth="2"
		/>
		<rect
			x="122.371"
			y="51.9849"
			width="10.904"
			height="5.36027"
			rx="2.68014"
			transform="rotate(-24.4158 122.371 51.9849)"
			stroke="#2A2D33"
			strokeWidth="2"
		/>
		<path
			d="M63.8599 29.5842L63.8231 28.5849C63.4193 28.5997 63.0641 28.8562 62.9229 29.2348L63.8599 29.5842ZM73.1907 29.2408L74.0993 28.8231C73.9305 28.4561 73.5576 28.2267 73.1539 28.2415L73.1907 29.2408ZM64.543 31.3496C64.543 30.8497 64.633 30.3731 64.7969 29.9336L62.9229 29.2348C62.677 29.8945 62.543 30.6075 62.543 31.3496H64.543ZM68.5967 35.4033C66.3579 35.4033 64.543 33.5884 64.543 31.3496H62.543C62.543 34.693 65.2533 37.4033 68.5967 37.4033V35.4033ZM72.6503 31.3496C72.6503 33.5884 70.8354 35.4033 68.5967 35.4033V37.4033C71.94 37.4033 74.6503 34.693 74.6503 31.3496H72.6503ZM72.2821 29.6586C72.5182 30.172 72.6503 30.744 72.6503 31.3496H74.6503C74.6503 30.4498 74.4534 29.5933 74.0993 28.8231L72.2821 29.6586ZM73.1539 28.2415L63.8231 28.5849L63.8967 30.5835L73.2275 30.2402L73.1539 28.2415Z"
			fill="#2A2D33"
		/>
		<path
			d="M77.2891 29.0882L83.9786 28.8496C84.4395 29.5013 84.7104 30.2969 84.7104 31.1558C84.7104 33.3641 82.9202 35.1542 80.712 35.1542C80.1463 35.1542 79.6081 35.0367 79.1204 34.8248"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M83.9645 28.8635C84.1385 27.8865 83.9938 25.4879 82.0229 23.7096C82.3164 22.6804 82.2003 18.9207 81.293 16.7883"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M63.8594 29.5855L74.2883 29.1987C74.3512 28.5641 74.2051 26.8979 73.118 25.3098"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx="70.0392" cy="31.8869" r="1.20326" fill="#2A2D33" />
		<circle cx="49.9728" cy="62.3449" r="1.20326" fill="#2A2D33" />
		<circle cx="78.3204" cy="65.0365" r="1.20326" fill="#2A2D33" />
		<circle cx="81.9181" cy="31.4857" r="1.20326" fill="#2A2D33" />
		<path
			d="M59.063 34.7915C60.0139 32.5569 61.3175 26.7334 59.0352 21.4844L64.2415 23.1869L63.1994 17.5078"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M51.2434 36.7991C43.1279 30.1208 40.8833 22.6242 46.964 18.7612L42.6719 13.7536H47.5356C48.0938 13.7536 48.491 13.2109 48.3223 12.6788L46.964 8.39505L52.1332 10.512C59.5372 -1.44301 78.4077 -4.55549 89.426 15.5726C86.5656 14.7879 81.289 16.6181 76.1463 18.5697C72.3536 20.0091 66.1798 18.4288 63.1999 17.4999"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M25.5039 71.4524C25.5039 71.4524 32.9474 67.2585 40.4146 65.3606"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M30.9766 76.3149L40.6792 71.6775"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M25.5273 92.3214L35.2782 79.1204"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M31.1758 99.0474L32.4873 82.9609"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M53.9414 63.8333C55.1674 65.2249 59.0507 68.0082 64.7763 68.0082C70.5019 68.0082 73.7585 65.3381 74.4378 64.2612"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M74.5009 63.9448C74.9921 60.1276 74.5721 56.2451 74.1761 54.3093L81.8949 57.5883C82.459 60.2141 82.2431 63.304 81.8647 65.6887C81.5818 67.4708 79.9856 68.6689 78.1811 68.6689C75.8636 68.6689 74.2051 66.2433 74.5009 63.9448Z"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M62.4318 88.9766C60.2644 92.7858 54.7356 92.7894 49.4935 92.5888C46.1548 92.461 42.3515 91.2555 39.8945 90.0605"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M63.6875 84.5879L64.267 76.0015"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M58.1326 112.076L59.5229 106.439"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M40.6797 100.899C45.9393 102.818 51.4518 103.356 58.0977 101.982C58.9531 101.805 59.7399 101.377 60.1091 100.585C60.539 99.6632 61.0379 98.1416 61.4079 95.8228"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M64.8008 101.7C65.5089 102.121 67.577 102.869 70.1847 102.488"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M65.8945 91.4691C67.7243 92.2662 72.6753 93.3652 78.4584 91.7658C79.114 91.5845 79.732 91.2703 80.1679 90.7481C83.1087 87.2248 86.3461 76.2236 82.1564 63.7778"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M82.2695 59.1475L92.3253 67.3802C92.3253 67.3802 91.1189 71.9466 84.1557 77.7981"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M90.7486 70.4287L82.2695 64.0564"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M88.5097 73.452L83.793 69.7935"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M74.9023 103.48C76.6172 102.1 79.7871 97.8477 78.7464 91.698"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="55.3945"
			y="17.9033"
			width="5.12099"
			height="5.12099"
			fill="#2A2D33"
		/>
		<path
			d="M62.6142 8.84103C64.0361 10.3308 64.7193 12.4759 64.2532 14.6411C63.7872 16.8062 62.2818 18.4802 60.3729 19.253C58.9511 17.7632 58.2679 15.6181 58.7339 13.453C59.2 11.2878 60.7054 9.61383 62.6142 8.84103Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M50.036 19.3398C51.8865 20.2434 53.2719 22.018 53.5863 24.2103C53.9008 26.4026 53.0701 28.495 51.5482 29.8824C49.6976 28.9788 48.3123 27.2042 47.9978 25.0119C47.6833 22.8196 48.514 20.7272 50.036 19.3398Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M73.2524 10.7705C73.2928 12.8295 72.3458 14.872 70.5385 16.1521C68.7312 17.4322 66.4903 17.6478 64.5613 16.9266C64.521 14.8676 65.468 12.8251 67.2752 11.545C69.0825 10.2649 71.3235 10.0493 73.2524 10.7705Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M69.4303 20.9661C69.8552 20.4393 69.7853 19.6774 69.2717 19.2367C67.9974 18.1431 66.2989 17.5465 64.4945 17.7119C62.6902 17.8774 61.1285 18.7729 60.0743 20.08C59.6494 20.6068 59.7192 21.3686 60.2328 21.8094C61.5072 22.9029 63.2057 23.4995 65.01 23.3341C66.8143 23.1687 68.376 22.2731 69.4303 20.9661Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M56.3824 13.3596C57.9636 14.6791 58.8849 16.7332 58.6666 18.9372C58.4483 21.1411 57.1418 22.9746 55.3326 23.9582C53.7514 22.6387 52.8301 20.5846 53.0484 18.3806C53.2667 16.1767 54.5732 14.3433 56.3824 13.3596Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M63.0369 23.5436C61.3087 22.4236 59.0739 22.1513 57.0332 23.0119C54.9925 23.8725 53.6277 25.6629 53.2234 27.6822C54.9516 28.8021 57.1864 29.0745 59.2271 28.2139C61.2677 27.3533 62.6326 25.5629 63.0369 23.5436Z"
			fill="#FEEA00"
			stroke="#2A2D33"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
)

export default FryEnterprise
