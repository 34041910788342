import * as React from "react"
import { SVGProps } from "react"

const Apple = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16"
			 height="19"
			 viewBox="0 0 16 19"
			 fill="none"
			 xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.2831 10.0778C13.2916 9.35114 13.4845 8.63853 13.8437 8.00682C14.2029 7.37511 14.7167 6.845 15.3369 6.46622C14.9425 5.90406 14.4226 5.44151 13.8184 5.11522C13.2142 4.78894 12.5422 4.6079 11.8559 4.58645C10.4025 4.4298 8.98401 5.45671 8.23559 5.45671C7.48716 5.45671 6.32971 4.58645 5.09394 4.58645C4.29547 4.61242 3.51738 4.84495 2.83559 5.26135C2.1538 5.67775 1.5916 6.26378 1.20386 6.96227C-0.440933 9.91246 0.777435 14.168 2.38742 16.5526C3.19677 17.71 4.12795 18.998 5.37242 18.9458C6.6169 18.8936 7.02592 18.18 8.48796 18.18C9.95 18.18 10.3503 18.9458 11.6122 18.9197C12.8741 18.8936 13.7269 17.7622 14.5015 16.5961C15.0828 15.7758 15.5293 14.868 15.8243 13.907C15.0714 13.5889 14.4289 13.0562 13.9769 12.3752C13.525 11.6942 13.2837 10.8951 13.2831 10.0778Z"
			fill="#ACACB3" />
		<path
			d="M10.8753 3.03722C11.5811 2.18951 11.9277 1.09964 11.8413 0C10.7626 0.116423 9.76629 0.631651 9.04778 1.44464C8.69828 1.84287 8.43075 2.30612 8.26052 2.80787C8.09028 3.30963 8.02067 3.84003 8.05568 4.36872C8.5957 4.37404 9.12991 4.25701 9.61824 4.02641C10.1066 3.79581 10.5364 3.45762 10.8753 3.03722Z"
			fill="#ACACB3" />
	</svg>

)

export default Apple
