import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import { getData } from "./data"
import ConsultationCtaFormA from "../../../components/_V2/ConsultationCtaFormA"
import FryEnterprise from "../../../assets/_V2/svg/FryEnterprise"
import EnterpriseCtaForm from "../../../components/_V2/EnterpriseCtaForm"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function EnterpriseCta(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<section className={classNames(styles.enterpriseCta, props.className)}>
				<div className={styles.enterpriseCta__container}>
					{<FryEnterprise className={styles.enterpriseCta__icon} />}

					<div className={styles.enterpriseCta__content}>
						<h2 className={styles.enterpriseCta__title}>{data.title}</h2>

						<p className={styles.enterpriseCta__subtitle}>{data.subTitle}</p>
						<p className={styles.enterpriseCta__description}>
							{data.description}
						</p>
					</div>

					<button
						className={styles.enterpriseCta__button}
						onClick={handleModalStatus}
					>
						{data.ctaButtonText}
					</button>
				</div>
			</section>

			<EnterpriseCtaForm isOpen={isModalOpen} onClose={handleModalStatus} />
		</>
	)
}
