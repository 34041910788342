import { Tooltip } from 'react-tippy'
import React from 'react'
import '../../../styles/_V2/components/apple-tooltip.scss'
import styles from './styles.module.scss'
import Apple from '../../../assets/_V2/svg/Apple'

export default function AppleTooltip(props) {
  return (
    <Tooltip
      html={(
        <div className={styles.tooltip__text}>
          Доступно только для iOS
        </div>
  )}
      interactive
      arrow
      position="bottom"
      trigger="mouseenter"
      theme="dark"
      className={styles.tooltip}
      sticky

    >
      <Apple />
    </Tooltip>
  )
}
