import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import MinusIcon from "../../../assets/_V2/svg/MinusIcon"
import PlusIcon from "../../../assets/_V2/svg/PlusIcon"

interface Question {
	question: string
	answer: JSX.Element
}

interface Props {
	className?: string
	question: Question
}

export default function Question(props: Props) {
	const [isQuestionOpened, setIsQuestionOpened] = useState(false)

	const onClickHeader = () => {
		setIsQuestionOpened(!isQuestionOpened)
	}

	const questionEl = useRef("")

	const [openedQuestionHeight, setOpenedQuestionHeight] = useState(0)
	const [closedQuestionHeight, setClosedQuestionHeight] = useState(0)

	const questionHeight = isQuestionOpened
		? openedQuestionHeight
		: closedQuestionHeight

	useEffect(() => {
		function calculateAccordionHeights() {
			let tableHeight: number = 0

			Array.from(questionEl.current.children).forEach((child, index) => {
				if (index === 0) {
					setClosedQuestionHeight(child.offsetHeight)
				}

				tableHeight = tableHeight + child.offsetHeight
			})

			setOpenedQuestionHeight(tableHeight + 10)
		}

		calculateAccordionHeights()

		window.addEventListener("resize", calculateAccordionHeights)

		return () => {
			window.removeEventListener("resize", calculateAccordionHeights)
		}
	}, [])

	return (
		<div
			className={classNames(styles.question, props.className)}
			ref={questionEl}
			style={{
				height: `${questionHeight}px`,
			}}
		>
			<div className={styles.question__question} onClick={onClickHeader}>
				<h3>{props.question.question}</h3>

				{isQuestionOpened ? <MinusIcon /> : <PlusIcon />}
			</div>

			<div className={styles.question__answer}>{props.question.answer}</div>
		</div>
	)
}
