import { Locales } from "../../../localization/types";

export const getData = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return  {
			title: "Enterprise",
			subTitle: "Ірі кәсіпорындарға және франшизаларға арналған тариф",
			description:
				"Өтінімді қалдырыңыз, және біз жеке шарттарды талқылау үшін байланысамыз.",
			ctaButtonText: "Өтінім қалдыру",
		}
	}

	return {
		title: "Enterprise",
		subTitle: "Тариф для крупных предприятий и франшиз",
		description:
			"Оставь заявку, и мы свяжемся для обсуждения индивидуальных условий.",
		ctaButtonText: "Оставить заявку",
	}
}
export default {
	title: "Enterprise",
	subTitle: "Тариф для крупных предприятий и франшиз",
	description:
		"Оставь заявку, и мы свяжемся для обсуждения индивидуальных условий.",
	ctaButtonText: "Оставить заявку",
}
