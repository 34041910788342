import React from "react"
import FryEnterprise from "../../../assets/_V2/svg/FryEnterprise"
import styles from "./styles.module.scss"

export default {
	inputTitle: "Ваш телефон",
	modal: {
		customTitle: (
			<h2 className={styles.title}>Выгодные условия для сетей и франшиз</h2>
		),
		details: "Оставь заявку и получи персональное предложение по автоматизации",
		action: "Оставить заявку",
		successTitle: "Отлично!",
		successDetails: "Наш менеджер перезвонит вам",
		successAction: "Хорошо",
		logo: <FryEnterprise className={styles.icon} />,
	},
}
