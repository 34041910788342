import * as React from "react"
import { SVGProps } from "react"

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16 3.27197V28.7275"
			stroke="#CCCCCC"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M28.7266 15.9995H3.271"
			stroke="#CCCCCC"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
)

export default PlusIcon
