import React from "react"
import {Locales} from "../../../localization/types";
import CustomTooltip from "../../../components/_V3/CustomTooltip"
import AppleTooltip from "../../../components/_V3/AppleTooltip"

export const getData = (locale: Locales) => {
    if (locale === 'ru-BY') {
        return [
            {
                title: "Техническая поддержка 24/7",
                items: [
                    {
                        name: "Мессенджеры, чат, почта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Приоритетная выделенная линия",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассовый терминал",
                items: [
                    {
                        name: "Печать тикетов на кухню",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторы",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кухонный экран и управление очередью заказов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "ЕГАИС (интеграция DocsInBox)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с маркированными товарами (Честный знак)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Поддержка банковских терминалов (Оплата картой)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электронная очередь",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Экран покупателя для кассового терминала",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Выпуск готовых блюд (складские документы на терминале)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Складской учет и фудкост, финансы и персонал",
                items: [
                    {
                        name: "Настраиваемые виджеты оперативного учета",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый учет - склад и техкарты",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период проведения документов \"задним числом\"",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период хранения документов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Настройка полей и прав доступа",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый финансовый учет",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Продвинутый учет и аналитика, конструктор отчетов, ABC анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Доставка",
                items: [
                    {
                        name: "Работа с заказами на самовывоз",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с заказами на доставку на кассе",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Своя доставка через сервис Яндекс.Доставка",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление сетью ресторанов",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке (Единая отчетность для разных заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью (Сеть из нескольких заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности (Одна программа лояльности на все заведения в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление франшизой",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Дополнительные приложения",
                items: [
                    {
                        name: "Сайт для организации доставки на платформе Quick Resto",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Мобильное приложение для клиентов доставки White Label",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграции и API",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Подключаемые модули",
                items: [
                    {
                        name: "Дополнительный терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 40,
                                year: 400,
                                yearOld: 400,
                            },
                            max: 9,
                        },
                        start: false,
                        standard: {
                            type: "counter",
                            price: {
                                month: 56,
                                year: 600,
                                yearOld: 600,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 85,
                                year: 900,
                                yearOld: 900,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: (<span>
                    Лояльность.Карты
                    <br />
                    (электронные карты лояльности)
                    </span>
                        ),
                        web: {
                            type: "value",
                            price: {
                                month: "от 70 BYN / мес",
                                year: "от 70 BYN / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 70 BYN / мес",
                                year: "от 70 BYN / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 70 BYN / мес",
                                year: "от 70 BYN / мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 70 BYN / мес",
                                year: "от 70 BYN/ мес"
                            }
                        },
                    },
                    {
                        name: "Лояльность.360",
                        web: {
                            type: "value",
                            price: {
                                month: "от 175 BYN / мес",
                                year: "от 175 BYN / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 175 BYN / мес",
                                year: "от 175 BYN / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 175 BYN / мес",
                                year: "от 175 BYN / мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 175 BYN / мес",
                                year: "от 175 BYN / мес"
                            }
                        },
                    }
                ],
            }
        ]
    }

    if (locale === 'kz-KZ') {
        return [
            {
                title: "Техникалық қолдау 24/7",
                items: [
                    {
                        name: "Мессенджерлер, чат, пошта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Басым бөлінген желі",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассалық терминал",
                items: [
                    {
                        name: "Фискалдау",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Асүйге тикеттерді басып шығару",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторлар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Асүй экраны және тапсырыстар кезегін басқару",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Банк терминалдарын қолдау (Картамен төлеу)",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электрондық кезек",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассалық терминалға арналған сатып алушының экраны",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Дайын тағамдарды шығару (терминалдағы қойма құжаттары)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қоймалық есепке алу және фудкост, қаржы және персонал",
                items: [
                    {
                        name: "Жедел есепке алудың теңшелетін виджеттері",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Негізгі есепке алу - қойма және техкарталар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Құжаттарды бұрынғы күнмен жүргізу кезеңі",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Құжаттарды сақтау кезеңі",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассадағы қауіпті операциялар мен әрекеттер, қалдықтар лимиттері туралы хабарламалар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Өрістерді және кіру құқықтарын теңшеу",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Негізгі қаржылық есепке алу",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Ілгерінді есепке алу және аналитика, есептер конструкторы, АВС талдау",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Жеткізу",
                items: [
                    {
                        name: "Өзімен әкетуге тапсырыстармен жұмыс істеу",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассада жеткізуге тапсырыстармен жұмыс істеу",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Қонақтарды телефон нөмірі, QR коды немесе іздеу арқылы сәйкестендіру",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-талдау",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Мейрамханалар желісін басқару",
                items: [
                    {
                        name: "Шоғырландырылған есептілік және бұлтта желіні басқарудың негізгі элементтері (Бір бұлт шеңберінде түрлі мекемелер үшін бірыңғай есептілік)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Орталықтандырылған есепке алу және желіні басқарудың ілгерінді құралдары (Бір бұлт шеңберінде бірнеше мекемелерден тұратын желі)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Жаһандық адалдық бағдарламалары (Бір бұлт шеңберінде барлық мекемелерге бір адалдық бағдарламасы)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Франшизаны басқару",
                items: [
                    {
                        name: "Шоғырландырылған есептілік және бұлтта желіні басқарудың негізгі элементтері",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Орталықтандырылған есепке алу және желіні басқарудың ілгерінді құралдары",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Жаһандық адалдық бағдарламалары",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қосымша қосымшалар",
                items: [
                    {
                        name: "Menuquickresto.kz платформасында дайын жеткізу сайты",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "White Label жеткізу клиенттеріне арналған мобильді қосымша",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграциялар және АРІ",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "DocsInBox",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Postilla",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қосылатын модульдер",
                items: [
                    {
                        name: "Қосымша терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 3000,
                                year: 30000,
                            },
                            max: 99,
                        },
                        start: {
                            type: "counter",
                            price: {
                                month: 8550,
                                year: 85500,
                            },
                            max: 1,
                        },
                        standard: {
                            type: "counter",
                            price: {
                                month: 11350,
                                year: 113500,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 14200,
                                year: 142000,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: (<span>
                    Лояльность.Карты
                    <br />
                    (электронные карты лояльности)
                    </span>
                        ),
                        web: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸/ мес",
                                year: "от 9990 ₸ / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸ / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸/ мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸/ мес"
                            }
                        },
                    },
                    {
                        name: "Лояльность.360",
                        web: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                    }
                ],
            }
        ]
    }

    if (locale === 'ru-KZ') {
        return [
            {
                title: "Техническая поддержка 24/7",
                items: [
                    {
                        name: "Мессенджеры, чат, почта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Приоритетная выделенная линия",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассовый терминал",
                items: [
                    {
                        name: "Фискализация",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Печать тикетов на кухню",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторы",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кухонный экран и управление очередью заказов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Поддержка банковских терминалов (Оплата картой)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электронная очередь",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Экран покупателя для кассового терминала",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Выпуск готовых блюд (складские документы на терминале)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Складской учет и фудкост, финансы и персонал",
                items: [
                    {
                        name: "Настраиваемые виджеты оперативного учета",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый учет - склад и техкарты",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период проведения документов \"задним числом\"",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период хранения документов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Настройка полей и прав доступа",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый финансовый учет",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Продвинутый учет и аналитика, конструктор отчетов, ABC анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Доставка",
                items: [
                    {
                        name: "Работа с заказами на самовывоз",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с заказами на доставку на кассе",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление сетью ресторанов",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке (Единая отчетность для разных заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью (Сеть из нескольких заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности (Одна программа лояльности на все заведения в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление франшизой",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Дополнительные приложения",
                items: [
                    {
                        name: "Готовый сайт доставки на платформе menuquickresto.kz",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Мобильное приложение для клиентов доставки White Label",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграции и API",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "DocsInBox",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Postilla",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Подключаемые модули",
                items: [
                    {
                        name: "Дополнительный терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 3000,
                                year: 30000,
                            },
                            max: 99,
                        },
                        start: {
                            type: "counter",
                            price: {
                                month: 8550,
                                year: 85500,
                            },
                            max: 1,
                        },
                        standard: {
                            type: "counter",
                            price: {
                                month: 11350,
                                year: 113500,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 14200,
                                year: 142000,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: (<span>
                    Лояльность.Карты
                    <br />
                    (электронные карты лояльности)
                    </span>
                        ),
                        web: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸/ мес",
                                year: "от 9990 ₸ / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸ / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸/ мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 9990 ₸ / мес",
                                year: "от 9990 ₸/ мес"
                            }
                        },
                    },
                    {
                        name: "Лояльность.360",
                        web: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "от 25990 ₸ / мес",
                                year: "от 25990 ₸ / мес"
                            }
                        },
                    }
                ],
            }
        ]
    }

    return [
        {
            title: "Техническая поддержка 24/7",
            items: [
                {
                    name: "Бесплатное внедрение и обучение",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Мессенджеры, чат, почта",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Телефон 24/7",
                    cashier: false,
                    smart: true,
                    pro: true,
                },

            ],
        },
        {
            title: "Кассовый терминал",
            items: [
                {
                    name: "Платформа",
                    cashier: "iOS, Android, Win",
                    smart: "iOS, Android, Win",
                    pro: "iOS, Android, Win"
                },
                {
                    name: "Онлайн-касса, 54-ФЗ",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Количество заведений / терминалов",
                    cashier: "2",
                    smart: "5",
                    pro: "без ограничений",
                },
                {
                    name: "Количество юридических лиц (ИП / ООО)",
                    cashier: "1",
                    smart: "2",
                    pro: "без ограничений",
                },
                {
                    name: "Дополнительный кассовый терминал",
                    cashier: "990 ₽",
                    smart: "1990 ₽",
                    pro: "2990 ₽",
                },
                {
                    name: "Печать тикетов на кухню",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Поддержка банковских терминалов (Оплата картой)",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Поддержка СБП <AppleTooltip /></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Модификаторы блюд",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Электронные чаевые НетМонет, CloudTips и др.",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Шаблоны чеков",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Обслуживание у столиков (схема зала)",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Контроль за наличными <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Информация по открытым чекам <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: <>История действий с заказом <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: <>Собственные типы оплат <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: <>Сервис оплаты за столиком Яндекс.Чек <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
            ],
        },
        {
            title: <>Складской учет и фудкост, <br />финансы и персонал</>,
            items: [
                {
                    name: "Базовый учет: склад и техкарты",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Количество складов",
                    cashier: "1",
                    smart: "без ограничений",
                    pro: "без ограничений",
                },
                {
                    name: "Дашборд руководителя",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Настройка прав доступа персонала",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Финансовый учёт (транзакции, счета, движение по счетам)",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Учет рабочего времени <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "График работы персонала",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Расчет зарплат <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Продвинутый учет и аналитика, конструктор отчётов, ABC-анализ",
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: <>Работа со складскими документами на терминале <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Маркировка, государственные сервисы и ЭДО",
            items: [
                {
                    name: <>ЕГАИС <CustomTooltip text="Через интеграцию с сервисами ЕГАИСИК или DocsInBox"/></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name:<>Работа с маркированными товарами <CustomTooltip text="Через интеграцию с DocsInBox"/></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>ЭДО <CustomTooltip text="Через интеграцию с DocsInBox"/></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Меркурий <CustomTooltip text="Через интеграцию с DocsInBox"/></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
            ],
        },
        {
            title: "CRM",
            items: [
                {
                    name: "Простые (фиксированные) скидки",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Ведение базы гостей",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Бонусные программы",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Промокоды, скидка от суммы заказа",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Абонемент, подарок от суммы заказа",
                    cashier: false,
                    smart: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Лояльность",
            items: [
                {
                    name: <>Лояльность.360 (сайт, приложение, Telegram-бот) <CustomTooltip text="В стоимость входит подписка Лояльность.Карты и API"/></>,
                    cashier: "4990 ₽",
                    smart: "4990 ₽",
                    pro: "4990 ₽",
                },
                {
                    name: "Дополнительное заведение (от 6го)",
                    cashier: "990 ₽",
                    smart: "990 ₽",
                    pro: "990 ₽",
                },
                {
                    name: "Конструктор сайта",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Лояльность.Карты",
                    cashier: "1990 ₽",
                    smart: "1990 ₽",
                    pro: "1990 ₽",
                },
                {
                    name: "Скидки навынос, комбо и специальные предложения",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Интернет-эквайринг (процессинг)",
                    cashier: "от 2.6%",
                    smart: "от 2.6%",
                    pro: "от 2.6%",
                },
                {
                    name: "Push-уведомления гостей через мобильное приложение или карты лояльности",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "RFM-анализ",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                // {
                //     name: "Глобальные программы лояльности (программы лояльности для сети заведений в рамках одного облака)",
                //     cashier: true,
                //     smart: true,
                //     pro: true,
                // },
                {
                    name: "API Лояльность.360",
                    cashier: "4990 ₽",
                    smart: "4990 ₽",
                    pro: "4990 ₽"
                }
            ]
        },
        {
            title: "Доставка",
            items: [
                {
                    name: "Работа с онлайн заказами на самовывоз",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Работа с онлайн заказами на доставку",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Работа с заказами на самовывоз на кассе <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Работа с заказами на доставку на кассе <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Яндекс.Доставка <AppleTooltip /></>,
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: "Яндекс.Еда",
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: "Деливери",
                    cashier: false,
                    smart: false,
                    pro: true,
                },
                {
                    name: "Купер",
                    cashier: false,
                    smart: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Управление сетью ресторанов",
            items: [
                {
                    name: "Единая отчетность для нескольких заведений в рамках одного облака",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Централизованный складской учет для сети заведений",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
            ],
        },
        {
            title: "Дополнительные приложения",
            items: [
                {
                    name: <>Экран покупателя для кассового терминала <AppleTooltip /></>,
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Приложение руководителя Quick Resto Manager",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: <>Электронная очередь <AppleTooltip /></>,
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "KDS (кухонный экран повара)",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
                {
                    name: "Экран сборщика",
                    cashier: false,
                    smart: true,
                    pro: true,
                },
            ],
        },
        {
            title: "Интеграции и API",
            items: [
                {
                    name: "1С",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "DoscInBox",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
                {
                    name: "API",
                    cashier: true,
                    smart: true,
                    pro: true,
                },
            ],
        },
    ]
}

export default [
    {
        title: "Характеристики",
        items: [
            {
                id: 'characteristics-1',
                name: "Бесплатный пробный период",
                web: "14 дней",
                start: "14 дней",
                standard: "14 дней",
                pro: "14 дней",
            },
            {
                id: 'characteristics-2',
                name: "Лицензия на 1 кассовый терминал",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-3',
                name: "Связь с кухней (чеки и экран повара)",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-3.5',
                name: "Безлимитная номенклатура",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-4',
                name: "Отчёты и аналитика",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-5',
                name: "Программы лояльности",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-6',
                name: "Приём заказов на доставку и самовывоз",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-7',
                name: "Доставка",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-8',
                name: "Собственные типы оплат",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-9',
                name: "ABC-анализ, фудкост, отчёт по гостям",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-10',
                name: "Финансовый модуль",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-11',
                name: "Учёт рабочего времени",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-12',
                name: "Расчёт зарплат сотрудникам",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-13',
                name: "Техническая поддержка 24/7",
                web: (
                    <span>
						Чат, почта,
						<br/>
						мессенджеры
					</span>
                ),
                start: (
                    <span>
						Чат, почта,
						<br/>
						мессенджеры
					</span>
                ),
                standard: "Чат, почта, телефон, мессенджеры",
                pro: "Приоритет",
            },
        ],
    },
    // {
    // 	title: "Подключаемые модули",
    // 	items: [
    // 		{
    // 			id: 'modules-1',
    // 			name: "Дополнительный терминал",
    // 			web: {
    // 				type: "counter",
    // 				price: {
    // 					month: 500,
    // 					year: 5000,
    // 					yearOld: 5000,
    // 				},
    // 			},
    // 			start: {
    // 				type: "counter",
    // 				price: {
    // 					month: 1500,
    // 					year: 15000,
    // 					yearOld: 15000,
    // 				},
    // 				max: 1,
    // 			},
    // 			standard: {
    // 				type: "counter",
    // 				price: {
    // 					month: 2000,
    // 					year: 20000,
    // 					yearOld: 20000,
    // 				},
    // 				max: 9,
    // 			},
    // 			pro: {
    // 				type: "counter",
    // 				price: {
    // 					month: 2500,
    // 					year: 25000,
    // 					yearOld: 25000,
    // 				},
    // 				max: 49,
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-2',
    // 			name: "Карты лояльности PassKit",
    // 			web: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			start: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-3',
    // 			name: "Электронное меню",
    // 			web: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			start: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			standard: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-4',
    // 			name: (
    // 				<span>
    // 					Приложение для гостей WLApp
    // 					<br />
    // 					(push-уведомления)
    // 				</span>
    // 			),
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 25080,
    // 					yearOld: 25080,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 25080,
    // 					yearOld: 25080,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-5',
    // 			name: (
    // 				<span>
    // 					Приложение для гостей WLApp
    // 					<br />
    // 					(push-уведомления + предзаказ)
    // 				</span>
    // 			),
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 45000,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 45000,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-6',
    // 			name: "Сайт для доставки с корзиной WLSite",
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 59990,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 59990,
    // 				},
    // 			},
    // 		},
    // 	],
    // },
    {
        title: "Прочие характеристики",
        items: [
            {
                id: 'other-1',
                name: "Управление складом",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-2',
                name: "Работа со складом с кассового терминала",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'other-3',
                name: "Приложение для руководителя",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-4',
                name: "Схема зала",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-5',
                name: "Интеграция с банковскими терминалами",
                web: false,
                start: true,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Управление персоналом",
        items: [
            {
                id: 'manage-1',
                name: "Ограничение прав доступа",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'manage-2',
                name: "Сотрудники и должности",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Интеграции",
        items: [
            {
                id: 'integration-1',
                name: "1С",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-2',
                name: "Меркурий",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-3',
                name: "ЕГАИС",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-4',
                name: "DocsInBox",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-5',
                name: "Чаевые по карте (Нетмонет, CloudTips)",
                web: false,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-6',
                name: "Delivery Club",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-7',
                name: "Яндекс Еда",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-8',
                name: "Яндекс Доставка",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Оплата заказов по QR-коду",
        items: [
            {
                id: 'qr-1',
                name: "Яндекс Чек",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
        ],
    },
]
