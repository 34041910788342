import React, { Dispatch } from "react"
import classNames from "classnames"

import { getData } from "./data"
import styles from "./styles.module.scss"
import FeaturesTable from "../../../components/_V2/prices/FeaturesTable"

interface Props {
	className?: string
	mobileSelectedTariff: string
	setModulesCost: Dispatch<any>
  currentPeriod: string
}

import {useLocalizationContext} from "../../../localization/useLocalizationContext";


export default function Features(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<section className={classNames(props.className, styles.features)}>
			{ getData(localizationContext.locale).map(featuresTable => (
				<FeaturesTable
					key={featuresTable.title}
					isOpened={true}
					mobileSelectedTariff={props.mobileSelectedTariff}
					setModulesCost={props.setModulesCost}
					currentPeriod={props.currentPeriod}
					data={featuresTable}
				/>
			))}
		</section>
	)
}
