import styles from "./styles.module.scss"
import '../../../styles/_V2/components/apple-tooltip.scss'
import Question from "../../../assets/_V2/svg/Question"
import { Tooltip } from "react-tippy"
import React from "react"

interface Props {
	text: string
}

export default function CustomTooltip(props :Props) {
	return (
		<Tooltip
			html={

				<div className={styles.tooltip__text}>
					{props.text}
				</div>

			}
			interactive
			arrow={true}
			position="bottom"
			trigger="mouseenter"
			className={styles.tooltip}
			sticky={true}
		>
			<Question  />
		</Tooltip>
	)
}

