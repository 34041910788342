import React from "react"

import {contacts as contactsDefault, contactsKz, contactsBy} from "../common/contacts"
import {Locales} from "../../../localization/types";

const PHONE_NUMBER_BY_LOCALE = {
	DEFAULT: ''
}

export const getData = (locale: Locales) => {
	let contacts = contactsDefault
	if (locale === 'ru-KZ') {
		contacts = contactsKz
	}

	if (locale === 'kz-KZ') {
		contacts = contactsKz
		return {
			blockName: "Жиі қойылатын сұрақтар",
			title: "Тағы не айтуға болады?",
			questions: [
				{
					question: "Әр тарифтің мүмкіндіктері туралы қайдан толығырақ білуге болады?",
					answer: (
						<div>
							Әр тариф туралы толық ақпаратты{" "}
							<a href="/price/compare/" target="_blank">
								кестеден
							</a> біле аласыз.
							<br />
							Егер сұрақтарыңыз қалса,{" "}
							<a href={`tel:${contacts.phone.value}`}>{contacts.phone.text}</a> нөмірі бойынша қоңырау шалыңыз немесе {" "}
							<a href={`mailto:${contacts.salesEmail}`}>{contacts.salesEmail}</a> {" "}
							поштасына жазсыңыз.
							<br />
							<a
								href={contacts.orderPricesUrl}
								target="_blank"
								rel="noreferrer"
							>
								Quick Resto тарифтері туралы бұйрық
							</a>
							.
						</div>
					),
				},
				{
					question:
						"Quick Resto енгізу кезінде қандай қосымша шығындар болуы мүмкін?",
					answer: (
						<div>
							Жазылу ақысын төлеуден басқа, Quick Resto басқа шығындарды талап етпейді. Біз пайдаланушылар
							саны, жаңартулар және мәселелерді шешу үшін қосымша ақы алмаймыз. Бұл ретте сіз жүйені теңшеу
							және персоналыд оқыту бойынша қосымша қызметтерге жеке тапсырыс бере аласыз.
						</div>
					),
				},
				{
					question:
						"Толық автоматтандыру, қызметкерлерді оқыту және басқалары мүмкін бе?",
					answer: (
						<div>
							Иә, сізге жайлы болу үшін біз толық автоматтандыруды жүргіземіз және персоналды оқыту бойынша қосымша қызметтер көрсетеміз.
							{" "}
							<a
								href="https://disk.yandex.ru/i/MhHO6yT-ozTwtA"
								target="_blank"
								rel="nofollow"
							>
								Сіз прайспен таныса аласыз
							</a>
							.
						</div>
					),
				},
				{
					question: "Quick Resto-мен қандай шарт жасалады?",
					answer: (
						<div>
							Quick Resto мен сіздің компанияңыз арасындағы қатынастарды {" "}
							<a href="/licenseAgreement/" target="_blank">
								лицензиялық шарт реттейді
							</a>
							.
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		contacts = contactsBy
	}

	return {
		blockName: "Часто задаваемые вопросы",
		title: "Что рассказать еще?",
		questions: [
			{
				question: "Где можно подробнее узнать о возможностях каждого тарифа?",
				answer: (
					<div>
						Подробную информацию о&nbsp;каждом тарифе вы можете изучить в{" "}
						<a href="/price/compare/" target="_blank">
							таблице
						</a>
						.
						<br />
						Если останутся вопросы, звоните по номеру{" "}
						<a href={`tel:${contacts.phone.value}`}>{contacts.phone.text}</a> или
						пишите на почту{" "}
						<a href={`mailto:${contacts.salesEmail}`}>{contacts.salesEmail}</a>
						.
						<br />
						<a
							href={contacts.orderPricesUrl}
							target="_blank"
							rel="noreferrer"
						>
							Приказ о тарифах Quick Resto
						</a>
						.
						<br />
						<a
							href={contacts.loyaltyPricesUrl}
							target="_blank"
							rel="noreferrer"
						>
							Приказ о тарифах Лояльность.360 от 30.08.2024
						</a>
						.
					</div>
				),
			},
			{
				question:
					"Какие дополнительные затраты могут быть при внедрении Quick Resto?",
				answer: (
					<div>
						Кроме оплаты подписки, других затрат Quick Resto не&nbsp;требует. Мы
						не берём доплат за количество пользователей, обновления и&nbsp;решение
						технических проблем. При этом вы можете индивидуально заказать
						дополнительные услуги по&nbsp;настройке системы и&nbsp;обучению
						персонала.
					</div>
				),
			},
			{
				question:
					"Возможна ли автоматизация «под ключ», обучение сотрудников и прочее?",
				answer: (
					<div>
						Да, для вашего комфорта мы&nbsp;проводим автоматизацию «под&nbsp;ключ»
						и&nbsp;оказываем дополнительные услуги по&nbsp;обучению персонала.
						Вы&nbsp;можете ознакомиться с{" "}
						<a
							href="https://disk.yandex.ru/i/MhHO6yT-ozTwtA"
							target="_blank"
							rel="nofollow"
						>
							прайсом
						</a>
						.
					</div>
				),
			},
			{
				question: "Какой договор заключается с Quick Resto?",
				answer: (
					<div>
						Отношения между Quick Resto и вашей компанией регулируются{" "}
						<a href="/licenseAgreement/" target="_blank">
							лицензионным договором
						</a>
						.
					</div>
				),
			},
		],
	}
}
