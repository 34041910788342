import React from "react"
import classNames from "classnames"
import { Navigation, Grid } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css/grid"
import "swiper/css"
import "swiper/css/navigation"

import { getData } from "./data"
import styles from "./styles.module.scss"

import "../../../styles/_V2/components/swiper.scss"
import ArrowLeftIcon from "../../../assets/_V2/svg/ArrowLeftIcon"
import ArrowRightIcon from "../../../assets/_V2/svg/ArrowRightIcon"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function Services(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	return (
		<section className={classNames(props.className, styles.services)}>
			<h2 className={styles.services__title}>{data.title}</h2>

			<Swiper
				className={styles.services__slider}
				modules={[Navigation, Grid]}
				breakpoints={{
					0: {
						slidesPerView: 1,
						spaceBetween: 20,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 30,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1320: {
						slidesPerView: 4,
						spaceBetween: 40,
						grid: {
							rows: 1,
							fill: "column",
						},
					},
				}}
				navigation={{
					nextEl: ".next",
					prevEl: ".prev",
				}}
				rewind={true}
				grabCursor={true}
			>
				{data.items.map((item, idx) => (
					<SwiperSlide key={idx} className={styles.services__slide}>
						<a href={item.href} target={item.hrefTarget} className={styles.servicesItem}>
							<div className={styles.servicesItem__icon}>{item.icon}</div>
							<div className={styles.servicesItem__content}>
								<span className={styles.servicesItem__title}>{item.title}</span>
								<span className={styles.servicesItem__price}>{item.price}</span>
							</div>
						</a>
					</SwiperSlide>
				))}

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonLeft,
						"prev"
					)}
				>
					<ArrowLeftIcon />
				</button>

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonRight,
						"next"
					)}
				>
					<ArrowRightIcon />
				</button>
			</Swiper>
		</section>
	)
}
